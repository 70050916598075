.questionsPage {
  display: flex;
  overflow-y: scroll;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.copiedContainer {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  transition-property: all;
  justify-content: center;
}

.copiedText {
  padding: 0.5rem;
  margin-top: 1.25rem;
  border-radius: 0.375rem;
  font-size: 2rem;
}

.questionDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  /* align-items: center; */
  width: 100%;
  gap: 2rem;
}

.questionDetails button {
  background: #fff;
  border: none;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
}

.questioneeDetails {
  margin-top: 0.5rem;
  font-size: 1.8rem;
  font-weight: 400;
}

button.questioneeDetails {
  padding: 1rem 4rem;
  background-color: #1f2937;
  color: #ffffff;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

button.questioneeDetails svg {
  width: 3rem;
  height: 3rem;
}

.questionsHeader {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.qr {
  display: flex;
  justify-content: center;
}

.questionsPage h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-top: 4rem;
}

.questionsPage ol {
  margin-top: 1rem;
}

.questionsPage ol li {
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.questionsPage ol li a {
  text-decoration: none;
}

.questionsWrapper {
  margin-top: 1rem;
}

.question {
  color: #1f2937;
  font-size: 2rem;
  padding-bottom: 1.5rem;
  margin-top: 1rem;
  cursor: grab;
  border-bottom: 1px solid #1f2937;
}

.lastQuestion {
  margin-top: 1.25rem;
  color: #1f2937;
  padding-bottom: 1.6rem;
  cursor: grab;
}

.retryBtn {
  padding: 1rem 5rem;
  background-color: #dc2626;
  color: #ffffff;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1.6rem;
  text-decoration: none;
}

.viewResponseBtn {
  padding: 1rem 4rem;
  background-color: #1f2937;
  color: #ffffff;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 1.6rem;
  width: fit-content;
  text-decoration: none;
}

.questionsBtns {
  display: flex;
  margin-top: 4rem;
  gap: 2rem;
  justify-content: center;
}

ol li>div {
  margin-bottom: 1rem;
  font-weight: 600;
}