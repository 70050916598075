.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 1.8rem 3rem;
  background-color: #1f2937;
  flex-wrap: wrap;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.headWrapper {
  display: flex;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
}

.navHead {
  font-size: 2rem;
  font-weight: 600;
}

.navBtn {
  color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #6b7280;
  background: #1f2937;
  font-size: 1.6rem;
  padding: 1rem;
  outline: none;
  cursor: pointer;
}

/* Tooltip styles */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  display: inline-block;
}

.tooltip-content {
  position: absolute;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: #333;
  color: white;
  font-size: 0.875rem;
  max-width: 250px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  white-space: normal;
  text-align: center;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 6px solid transparent;
}

/* Top tooltip (appears above element) */
.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
}

.tooltip-top::after {
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top-color: #333;
}

/* Bottom tooltip (appears below element) */
.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-bottom-color: #333;
}

/* Left tooltip (appears to the left of element) */
.tooltip-left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
}

.tooltip-left::after {
  left: 100%;
  top: 50%;
  margin-top: -6px;
  border-left-color: #333;
}

/* Right tooltip (appears to the right of element) */
.tooltip-right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
}

.tooltip-right::after {
  right: 100%;
  top: 50%;
  margin-top: -6px;
  border-right-color: #333;
}