body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}

::-webkit-scrollbar {
  display: none;
}

:root {
  font-size: 62.5%;
}

.page {
  display: flex;
  overflow-y: scroll;
  position: relative;
  flex-direction: column;
  padding: 0 3rem 3rem 2rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0px 999px #FFFFFF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ol {
  font-size: 1.2rem;
}